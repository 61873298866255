@import "bootstrap"

@font-face
  font-family: 'HKGrotesk'
  src: url('./fonts/HKGrotesk-Regular.woff2') format('woff2'), url('./fonts/HKGrotesk-Regular.woff') format('woff')
  font-weight: 400
  font-style: normal

@font-face
  font-family: 'HKGrotesk'
  src: url('./fonts/HKGrotesk-SemiBold.woff2') format('woff2'), url('./fonts/HKGrotesk-SemiBold.woff') format('woff')
  font-weight: 600
  font-style: normal

@font-face
  font-family: 'HKGrotesk'
  src: url('./fonts/HKGrotesk-Bold.woff2') format('woff2'), url('./fonts/HKGrotesk-Bold.woff') format('woff')
  font-weight: 700
  font-style: normal

.sign-up-container

  @include make-container()

  form
    padding-bottom: 5rem

    @media screen and (min-width: 576px)
      padding-bottom: 0

  @media screen and (min-width: 576px)
    width: 540px

[x-cloak]
  display: none !important

body
  font-family: HKGrotesk, system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"
  min-width: 320px

ul.check-mark-bullets

  li
    list-style-image: url('./images/check_mark_icon.svg')

header
  background-color: #8D31F5

  .navbar

    .navbar-toggler
      top: 0.75rem
      right: 0
      border: none

    .navbar-collapse

      @media screen and (max-width: 991px)
        position: absolute
        top: 74px
        background: #8D31F5
        padding-bottom: 1.25rem
        text-align: center

    .navbar-nav

      .nav-link
        color: #fff

        @media screen and (min-width: 992px)
          padding-right: 1rem
          padding-left: 1rem

.modal

  .login-modal-left-col
    background: url('./images/login-modal-bg.jpg')
    background-size: cover
    background-position-x: right

    @media screen and (min-width: 992px)
      min-height: 620px

  .forgot-pass-modal-left-col
    background: url('./images/forgot-pass-modal-bg.png')
    background-size: cover

    @media screen and (min-width: 992px)
      min-height: 620px

  .sign-up-modal-left-col
    background: url('./images/sign-up-modal-bg.jpg')
    background-size: cover

  .contact-us-modal-left-col
    background: url('./images/contact-us-modal-bg-alt.jpg')
    background-size: cover
    background-position-x: right

  .modal-content,
  .modal-header
    background: transparent

  .btn-close
    color: #fff
    background: none
    font-size: 2.25rem
    width: auto
    height: auto
    line-height: 1

  .modal-body
    background: #fff

.hero
  background-color: #8D31F5
  padding-top: 1.5rem

  @media screen and (min-width: 992px)
    padding-top: 2.5rem

  h1
    font-size: 2.5rem
    font-weight: 700
    line-height: 1.1
    color: #fff

    @media screen and (min-width: 1200px)
      font-size: 3.75rem

    @media screen and (min-width: 1400px)
      font-size: 4.425rem
      line-height: 1.125

  p
    color: #fff

.btn-primary
  --bs-btn-font-weight: 600
  --bs-btn-color: var(--bs-white)
  --bs-btn-bg: #{$q-purple}
  --bs-btn-border-color: #{$q-purple}
  --bs-btn-hover-color: var(--bs-white)
  --bs-btn-hover-bg: #{shade-color($q-purple, 10%)}
  --bs-btn-hover-border-color: #{shade-color($q-purple, 10%)}
  --bs-btn-active-color: var(--bs-btn-hover-color)
  --bs-btn-active-bg: #{shade-color($q-purple, 20%)}
  --bs-btn-active-border-color: #{shade-color($q-purple, 20%)}

.btn-dark
  --bs-btn-font-weight: 600
  --bs-btn-bg: #{$q-black}
  --bs-btn-border-color: #{$q-black}
  --bs-btn-hover-bg: #{tint-color($q-black, 15%)}
  --bs-btn-hover-border-color: #{tint-color($q-black, 15%)}
  --bs-btn-color: var(--bs-white)
  --bs-btn-hover-color: var(--bs-white)
  --bs-btn-active-color: var(--bs-btn-hover-color)
  --bs-btn-padding-y: 1.125rem
  --bs-btn-padding-x: 1.5rem
  --bs-btn-font-size: 1.05rem
  --bs-btn-line-height: 1.1
  min-width: 270px

.btn-outline-primary
  --bs-btn-font-weight: 600
  --bs-btn-color: #{$q-purple}
  --bs-btn-border-color: #{$q-purple}
  --bs-btn-hover-color: var(--bs-white)
  --bs-btn-hover-bg: #{$q-purple}
  --bs-btn-hover-border-color: #{$q-purple}
  --bs-btn-active-color: var(--bs-white)
  --bs-btn-active-bg: #{$q-purple}
  --bs-btn-active-border-color: #{$q-purple}

.btn-xl
  --bs-btn-padding-y: 0.75rem
  --bs-btn-padding-x: 1rem
  --bs-btn-font-size: 1rem
  --bs-btn-border-radius: 0.5rem
  --bs-btn-border-color: #{$gray-400}
  --bs-btn-color: #{$gray-500}

  @media screen and (min-width: 576px)
    --bs-btn-padding-y: 1rem
    --bs-btn-padding-x: 1.25rem
    --bs-btn-font-size: 1.125rem

.link-primary
  color: #{$q-purple} !important

  &:hover,
  &:focus
    color: #{shade-color($q-purple, 10%)} !important

.btn-q-light
  background: #f8f9fa
  border-color: #f8f9fa
  color: #8B5DF2
  font-weight: 600
  white-space: nowrap

  &.btn-lg
    font-size: 1.125rem
    text-transform: uppercase

  &:hover,
  &:active,
  &:focus
    background: #E9ECEF
    border-color: #E9ECEF
    color: #8B5DF2

.link-q
  font-weight: 600
  color: #373981
  text-decoration: none

  &:hover,
  &:active,
  &:focus
    color: #2F316F

.btn-fb
  background: #345287 !important
  font-weight: 600

  img
    display: inline-block
    vertical-align: -0.125rem
    margin-right: 0.125rem

  &:hover,
  &:focus
    background: #{shade-color(#345287, 10%)} !important

h2
  font-size: 2.25rem
  font-weight: 700

  @media screen and (min-width: 992px)
    font-size: 3rem

.bi
  width: 1em
  height: 1em

.ratio

  iframe
    border-radius: 0.325rem

.form-steps-nav

  .step
    height: 5px
    width: 28px
    border-radius: 0.5rem
    background: #{tint-color($q-purple, 60%)}

    &.active
      background: #{$q-purple}

  @media screen and (min-width: 576px)
    position: absolute
    top: 0
    left: 0
    width: 100%

.next-step-btn
  padding-right: calc(var(--bs-gutter-x) * 0.5)
  padding-left: calc(var(--bs-gutter-x) * 0.5)
  width: 100%
  position: fixed
  bottom: 1rem
  left: 0

  @media screen and (min-width: 576px)
    position: unset
    padding: 1.5rem 0

.gray-text
  color: #{$gray-600}

.recommendation

  h3
    font-size: 1.125rem
    width: 40%

  h4
    font-size: 1.25rem

form

  .form-label
    font-weight: 600
    font-size: 1.125rem
    margin-bottom: 0.25rem

  .pass-input
    border-right: none

  .toggle-pass
    background: #fff
    color: $gray-600
    border-color: $gray-400
    border-left: none

    svg
      pointer-events: none

    .bi-eye-slash
      display: none

    &.pass-shown

      .bi-eye-slash
        display: inline-block

      .bi-eye
        display: none

section

  .pre-header
    display: block
    font-size: 1.25rem
    font-weight: 600
    text-align: center

    &.pre-header-green
      color: #00BB98

    &.pre-header-blue
      color: #377CFD

    &.pre-header-purple
      color: #8D31F5

  &.apps-download
    background: #8D31F5

    h2,
    p
      color: #fff

  &.apps-download-a
    background-image: url('./images/yellow_shape_top_right_corner.png')
    background-position: top right
    background-size: 70px auto
    background-repeat: no-repeat

    @media screen and (min-width: 992px)
      background-size: 120px auto

    @media screen and (min-width: 1200px)
      background-size: 140px auto

    .left-side-img-desktop
      position: absolute
      top: -34px
      left: 5%
      max-width: 340px

      @media screen and (min-width: 1200px)
        top: -95px
        left: 10%
        max-width: 370px

  &.apps-download-b

    &.radial-bg
      background-image: url('./images/circles_bg_mobile.png')
      background-position: center 60%
      background-repeat: no-repeat

      @media screen and (min-width: 992px)
        background-image: url('./images/circles_bg.png')

  &.slider

    .images-grid

      img
        max-height: 650px

    .carousel

      .carousel-item

        img
          max-height: 520px

      .carousel-indicators
        bottom: -1.6rem

        & [data-bs-target]
          background-color: #8D31F5

  &.for-professionals-slider
    background: rgba(43, 89, 255, 0.03)

    .carousel

      .carousel-item

        .col-content

          @media screen and (max-width: 767px)

            h2
              display: flex
              align-items: center
              min-height: 130px

            p
              display: flex
              align-items: center
              min-height: 125px

        img
          max-height: 250px

          @media screen and (min-width: 768px)
            max-height: 550px

      .carousel-indicators
        bottom: -52px

        @media screen and (min-width: 768px)
          bottom: -51px

        @media screen and (min-width: 992px)
          bottom: 0

  &.premium-service

    .premium-service-hero
      background: #8D31F5
      background: linear-gradient(154.37deg, #350978 14.42%, #630B5E 86.54%)
      color: #fff

    .premium-service-benefits
      padding-bottom: 5rem

      @media screen and (min-width: 576px)
        margin-bottom: 0

      svg
        font-size: 1.125rem

footer

  h3
    font-size: 1rem
    font-weight: 600
    padding-top: 0.75rem
    margin-bottom: 2rem

  a
    text-decoration: none
    color: #000B33

  ul
    margin-bottom: 1rem

    @media screen and (min-width: 992px)
      margin-bottom: 2rem

    li
      margin-bottom: 1rem

// Pages with forms
.sign-up-page-left-col
  background: url('./images/sign-up-modal-bg.jpg')
  background-size: cover

.login-page-left-col
  background: url('./images/login-modal-bg.jpg')
  background-size: cover
  background-position-x: right

.forgot-pass-left-col
  background: url('./images/forgot-pass-modal-bg.png')
  background-size: cover

.sign-up-page-left-col,
.login-page-left-col,
.forgot-pass-left-col

  @media screen and (min-width: 2048px)
    background: #8D31F5 !important

.contact-us-left-col
  background-color: rgba(43, 89, 255, 0.02)

.q-forms,
.contact-us-left-content
  padding: 1.5rem
  width: 100%

  @media screen and (min-width: 576px)
    width: 75%

  @media screen and (min-width: 1200px)
    width: 490px

.scrollable-tou
  max-height: 150px
  overflow-y: scroll

.contact-us-left-content

  @media screen and (min-width: 1200px)
    width: 590px

.custom-v-select .vs__selected-options
  padding: 2px 2px 2px 5px

.custom-v-select .vs__dropdown-toggle
  border: 1px solid $gray-400

.custom-v-select .vs__search::placeholder
  color: $gray-600

.custom-v-select .vs__actions
  padding-right: 12px
